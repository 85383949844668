const INFO = {
	main: {
		title: "Kishore Kumar B",
		name: "Kishore Kumar B",
		email: "kishorekumar.b.23@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://twitter.com/",
		github: "https://github.com/",
		linkedin: "https://in.linkedin.com/in/kishore-kumar-b-46172955",
		instagram: "https://instagram.com/",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Data Solutions Architect",
		description:
			"Hello! My name is Kishore Kumar B, and I am a Data Solutions Architect & Engineer with over 10 years of experience in designing and implementing solutions to solve organization's complex data problems. I am passionate about creating data system design and implementing it with high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as an architect.",
	},

	about: {
		title: "I’m Kishore Kumar B. I live in Bangalore, India.",
		description:
			"As a Data Architect, I have extensive experience in diverse domains building scalable data solutions using both cloud and on-prem environments. I take responsibility for designing and implementing data architecture systems that optimize data storage & retrieval , develop ETL solutions, and manage automated data flows. I am excited to bring my expertise to the team and help you achieve your data goals. I enjoy exploring new technologies and experimenting with new ideas. Let's Connect to know more about me.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Project 1",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 2",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 3",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 4",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 5",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},
	],

	skills: [
		{
			title: "Languages",
			description:
				"Python, Scala, Java, SQL",
			// logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			// linkText: "View Project",
			// link: "https://github.com",
		},

		{
			title: "Big Data",
			description:
				"Cloudera, Hadoop, HDFS, Hive, Spark, Kafka, ElastiSearch, Kibana, Logstash, Airflow, No-SQL",
			// logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			// linkText: "View Project",
			// link: "https://github.com",
		},

		{
			title: "Google Cloud",
			description:
				"Cloud Storage, Dataproc, BigQuery, Cloud Functions, PubSub",
			// logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
			// linkText: "View Project",
			// link: "https://github.com",
		},

		{
			title: "AWS",
			description:
				"EMR, S3, Athena",
			// logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			// linkText: "View Project",
			// link: "https://github.com",
		},

		{
			title: "Azure",
			description:
				"ADLS, Databricks, Data Factory",
			// logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			// linkText: "View Project",
			// link: "https://github.com",
		},

		{
			title: "Others",
			description:
				"Git, Docker, Postman, Intellij, SBT, Maven",
			// logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			// linkText: "View Project",
			// link: "https://github.com",
		},
	],
};

export default INFO;
