import React from "react";

import Project from "../projects/project";

import Skill from "./skill";

import INFO from "../../data/user";

import "./styles/allSkills.css";

const AllSkills = () => {
	return (
		<div className="all-skills-container">
			{INFO.skills.map((skill, index) => (
				<div className="all-skills-skill" key={index}>
					<Skill
					    logo={skill.logo}
						title={skill.title}
						description={skill.description}
						// linkText={skill.linkText}
						// link={skill.link}
					/>
				</div>
			))}
		</div>
	);
};

export default AllSkills;
