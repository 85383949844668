const SEO = [
	{
		page: "home",
		description:
			"Hello! My name is Kishore Kumar B, and I am a Data Solutions Architect & Engineer with over 10 years of experience in designing and implementing solutions to solve organization's complex data problems. I am passionate about creating data system design and implementing it with high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as an architect.",
		keywords: ["Kishore", "Kishore Kumar", "Kishore Kumar B", "Kishore Kumar Data Architect", "Kishore Kumar Solutions Architect" , "Data Solutions Architect"],
	},

	{
		page: "about",
		description:
			"As a Data Architect, I have extensive experience in diverse domains building scalable data solutions using both cloud and on-prem environments. I take responsibility for designing and implementing data architecture systems that optimize data storage & retrieval , develop ETL solutions, and manage automated data flows. I am excited to bring my expertise to the team and help you achieve your data goals. I enjoy exploring new technologies and experimenting with new ideas. Let's Connect to know more about me.",
		keywords: ["Kishore", "Kishore Kumar", "Kishore Kumar B", "Kishore Kumar Data Architect", "Kishore Kumar Solutions Architect" , "Data Solutions Architect"],
	},

	{
		page: "articles",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
		keywords: ["Kishore", "Kishore Kumar", "Kishore Kumar B", "Kishore Kumar Data Architect", "Kishore Kumar Solutions Architect", "Data Solutions Architect"],
	},

	{
		page: "projects",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to.",
		keywords: ["Kishore", "Kishore Kumar", "Kishore Kumar B", "Kishore Kumar Data Architect", "Kishore Kumar Solutions Architect", "Data Solutions Architect"],
	},

	{
		page: "skills",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to.",
		keywords: ["Kishore", "Kishore Kumar", "Kishore Kumar B", "Kishore Kumar Data Architect", "Kishore Kumar Solutions Architect", "Data Solutions Architect"],
	},

	{
		page: "contact",
		description:
			"If you're interested in collaborating on a project, feel free to reach out to me. I'm always open to new ideas and opportunities.",
		keywords: ["Kishore", "Kishore Kumar B contact", "Kishore Kumar B", "Kishore Kumar Data Architect", "Kishore Kumar Solutions Architect", "Data Solutions Architect"],
	},
];

export default SEO;
